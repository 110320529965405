import { format, fromUnixTime } from 'date-fns';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      }
    };
  },
  methods: {
    parseUnixDate(date, formatString) {
      return format(fromUnixTime(date), formatString);
    }
  }
};